.content-aboutme {
}

.hello-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6.3rem;
  font-size: 2.2rem;
}

.apresentation-header {
  font-weight: 700;
  text-align: center;
}

.hello {
  border: 1px solid #062e64;
  border-radius: 2rem;
  padding: 0.4rem 1.4rem;
  font-size: 1rem;
  font-weight: 500;
}

.nome {
  color: #062e64;
}

.aboutme-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5rem;
  font-weight: 600;

  color: rgb(54, 54, 54);
}

.intro-aboutme {
  width: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.exp-aboutme {
  display: flex;
  justify-content: end;
  flex-direction: column;
  font-size: 2.2rem;
}

.exp {
  font-size: 1rem;
  text-align: end;
}

.imagem-aboutme {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  top:25rem;
}

.img-eu{
    width: 32rem;
}

.circle {
  position: absolute;
  z-index: -1;
  background-color: #062e64;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  top: 8rem;
}
