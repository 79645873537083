.main-meetme {
  margin: 6rem 0;
  padding: 2rem 0;
  background-color: #f2f4f7;
  color: rgb(54, 54, 54);
}

.content-meetme {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meet-me {
  text-align: center;
  font-size: 2.6rem;
  color: white;
  background-color: #062e64;
}

.minhasRedes-meetme {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  color: #062e64;
  background-color: white;
}

.logo-meetme {
  width: 5rem;
}

.me-meetme {
  width: 20rem;
  border-right: 2px solid #062e64;
  padding-right: 10rem;
}

.redes {
  width: 20rem;
  padding-left: 5rem;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-decoration: none;
}

.redes-meetme {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 2rem 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #062e64;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;
}

.btn-meetme {
  font-size: 1.6rem;
  font-weight: 600;
  color: white;
  background-color: #062e64;
  border: 2px solid #062e64;
  border-radius: 1rem;
  transition: 0.1s ease-in-out;
  height: 4rem;
  width: 56vw;
  cursor: pointer;

  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-meetme:hover {
  background-color: white;
  color: #062e64;
  scale: 1.02;
}
