.card {
  position: relative;
  background: rgba(119, 119, 119, 0.2);
  border-radius: 3rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(226, 226, 226, 0.664);
  margin-bottom: 4rem;
  transition: 0.3s ease-in-out;
}

.card:hover {
  transform: scale(0.96);
}

.title-card {
  color: white;
  padding: 1rem;
  text-align: start;
  margin-left: 3rem;
  font-weight: 300;
}

.linha {
  border-color: rgb(36, 36, 36);
}

.image-card {
  height: 22rem;
  border-radius: 3rem;
  margin-top: 6rem;
  -webkit-box-shadow: 0px -38px 3px -10px rgba(119, 119, 119, 0.253);
  -moz-box-shadow: 0px -38px 3px -10px rgba(112, 112, 112, 0.356);
  box-shadow: 0px -38px 3px -10px rgba(82, 82, 82, 0.356);
}

.link-card {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.seta-container {
  position: relative;
  background-color: rgba(22, 105, 230, 0.8);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -2rem 1rem 1rem -2rem;
  transition: transform 0.3s ease-in-out;
}

.card:hover .seta-container {
  transform: scale(1.1);
}
