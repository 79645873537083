.main-footer {
  background-color: rgb(36, 36, 36);
  color: white;

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 3rem;

  border-radius: 4rem 4rem 0 0;
}

.content-trabalhe {
  margin: 3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.content-trabalhe > h1 {
  font-size: 3rem;
  font-weight: 700;
}

.content-trabalhe > button {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 2px solid white;
  border-radius: 1rem;
  color: #062e64;
}

.linha {
  width: 100%;
  border-color: #062f646b;
  margin: 2rem;
}

.infos-footer {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo-footer {
  width: 10rem;
  background-color: white;
}

.redes-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.logos-footer1 {
  width: 3rem;
}
.logos-footer2 {
  width: 3rem;
  background-color: white;
  padding: 0.6rem 0;
  border-radius: 0.4rem;
}
.logos-footer3 {
  width: 3rem;
}

.navegacao-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.link-footer {
  color: white;
  text-decoration: none;
}
.ul-footer{
    list-style: none;
    text-align: end;
    margin-top: 1rem;
    font-size: 1.1rem;
}

.copy-footer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: .6rem;
}